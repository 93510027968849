/* Styles for loginPage */
.loginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
}

/* Heading */
.heading-loginPage {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

/* Input fields */
.input-loginPage {
    width: 100%;
    max-width: 281px;
    margin: 10px 0;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px
}

/* Styles to align PhoneInput with other input fields */
.phone-input-container .form-control {
    width: 100%;
    max-width: 400px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 25px;
    box-sizing: border-box; /* Ensures consistent sizing */
}

.phone-input-container .flag-dropdown {
    height: 100%; /* Ensures the flag select box aligns vertically */
    border: 1px solid #ccc;
    border-right: none; /* Matches the input style */
    border-radius: 5px 0 0 5px; /* Makes the flag select box match the input */
}

.phone-input-container input {
    width: calc(100% - 60px); /* Reserves space for the flag select box */
    padding-left: 60px; /* Adjust padding to avoid overlap with the flag */
    box-sizing: border-box;
    height: 45px; /* Ensures consistent height with other input fields */
}

/* Button styles */
.button-loginPage {
    width: 100%;
    max-width: 100px;
    padding: 10px;
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-loginPage:hover {
    background-color: #d41d21;
}

/* OTP input container */
.otp-input-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.otp-input {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Toggle between OTP and Password login */
.toggle-login-method {
    margin-top: 20px;
    font-size: 14px;
}

/* Error message styling */
.error {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}
