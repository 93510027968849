/* General table styles */
th {
  /* background: linear-gradient(120deg, #24242761, #39aade);  */
  color: whitesmoke; /* Header text color */
  text-align: left;
  padding: 10px;
  background-color: #e31e25; 
  position: sticky;
  top: 0;
  z-index: 2; /* Ensure it stays above the scrolling content */
  box-shadow: inset 0 -1px 0 0 #ddd;
}

td {
  /* background-color: linear-gradient(120deg, black, #4686a6); Cell background gradient */
  /* color:#333; Text color for table cells */
  padding: 10px;
  text-align: left;
  /* border-bottom: 1px solid #ddd; Bottom border for table cells */
}

.table-container {
  overflow-y: scroll;
  max-height: 465px; /* Limit table height for scroll */
  /* border: inset; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow for the table container */
}

/* Table full width and collapsed borders */
table {
  border-collapse: collapse;
  width: 100%;
}

.v{
  display: flex;
    justify-content: space-between;
}
/* Pagination controls styles */
.pagination-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.pagination-button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #ed050e14;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-button.active {
  background-color: #e31e25;
  font-weight: bold;
}

.pagination-button:hover {
  background-color: #e31e25;
}

.pagination-button:disabled {
  background-color: #a6b3bb;
  cursor: not-allowed;
}
td img{
  width:50px;
}
th{
  height:40px;
}
/* Table row hover effect */
tr {
  height: 60px;
  /* background:#c89293 ; */

}

tr:hover {
  border-bottom: 3px solid #ccc;
  /* background: grey; */
  color:#000000;
  font-weight: 500;
  /* box-shadow: 0 0 15px 10px rgba(10, 10, 10, 0.888); */
  border-radius: 10px;
}

.entries-info {
  margin: 10px 0;
  font-size: 0.9rem;
  color: #333;
}

/* Search container styles */
.search-container {
  margin-bottom: 15px;
}

.search-input {
  padding: 8px;
  width: 100%;
  max-width: 300px;
  border: 2px solid #4686a6;
  border-radius: 4px;
  outline: none;
  margin-top: 16px;
}

.search-input:focus {
  border-color: #285162;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Icon styles for action buttons */
.view-icon, .edit-icon, .delete-icon {
  cursor: pointer;
  color: rgb(45, 42, 42);
  transition: color 0.2s ease;
}

.view-icon:hover {
  color: #285162;
}

.edit-icon:hover {
  color: #f3e709;
}

.delete-icon:hover {
  color: red;
}

/* Action buttons alignment */
.action-icons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

th .sort-icon{
  color:gainsboro;
  /* padding-top: 10px; */
}
th .sort-icon:hover{
  color:whitesmoke;
}
.search-sync-container{
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  margin-bottom: 20px; 
}
/* .search-container:hover{
  box-shadow: 0 0 10px 5px rgba(16, 141, 39, 0.875); 
} */
.search-container{
  flex: 1; 
  margin-right: 10px;
}
.sync-container{
  display: flex; 
  align-items: center; 
  gap: 5px; 
  padding: 8px; 
  /* background: #28a745;  */
  color: #fff; 
  border-radius: 5px; 
  cursor: pointer;
}
.sync-button{
display: flex; 
align-items: center; 
gap: 5px; 
padding: 10px; 
background: darkgreen;
border-left: blue;
border-top: blue;
border-right:  lightgreen;
border-bottom: 4px solid lightyellow; 
color: #fff; 
border-radius: 5px; 
cursor: pointer;
box-shadow: 0 0 10px rgba(16, 141, 39, 0.875); 
}
.sync-button:hover{
  background:limegreen ;
  border-left: blue;
  border-top: blue;
  border-right: 4px solid lightgreen;
  border-bottom: 4px solid lightyellow; 
  box-shadow: 0 0 10px 5px rgba(16, 141, 39, 0.875); 
}
.filter-container select{
  height: 42px;
  background: lightslategrey;
  color:whitesmoke;
  border-radius: 5px;
  border-bottom: 4px solid white;
  /* border-right: 4px solid lightgreen;
  border-top: 6px solid lightgreen;
  border-left: 0px solid cadetblue; */
}
.filter-container select:hover{
  background:limegreen ;
  box-shadow: 0 0 10px 5px rgba(16, 141, 39, 0.875); 
}

.no-records-container {
  text-align: center;
  margin-top: 50px;
}

.no-records-message {
  font-size: 34px;
  color: #ff0000;
  animation: fadeIn 2.5s ease-in-out infinite;
  font-weight: bold;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.verify-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.verify-button:hover {
  background-color: #218838;
}

.verify-button.verified {
  background-color: grey;
  cursor: not-allowed;
}
