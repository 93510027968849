/* Base styles for dashboard container */
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px); /* Adjusted height for header and footer */
    padding: 20px;
    box-sizing: border-box;
}

/* Card container for holding all the cards */
.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    width: 100%; /* Full width for responsiveness */
    max-width: 1200px; /* Limit max width for large screens */
}

/* Individual card styling */
.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px; /* Default width */
    height: 200px; /* Default height */
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease; /* Add a smooth hover effect */
}

/* Hover effect for cards */
.card:hover {
    transform: translateY(-5px); /* Lift card slightly on hover */
}

/* Card title styling */
.card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

/* Card content styling */
.card-content {
    font-size: 1rem;
    color: #555;
}

/* Styling for links in cards */
.card-links {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full height of the card */
    width: 100%; /* Full width of the card */
    line-height: 0;
}

/* Hover effect for card links */
.card-links:hover {
    color: #e31e25; /* Change color on hover */
}

/* Responsive adjustments */

/* Large screens (1200px and above) */
@media (min-width: 1200px) {
    .card-container {
        gap: 40px; /* Increase gap between cards on large screens */
    }
}

/* Medium screens (e.g., tablets and small desktops, between 768px and 1200px) */
@media (max-width: 1200px) and (min-width: 768px) {
    .card-container {
        gap: 30px;
    }

    .card {
        width: 250px; /* Adjust card width for smaller screens */
        height: 180px; /* Adjust card height for smaller screens */
    }

    .card-title {
        font-size: 1.3rem; /* Adjust font size for smaller screens */
    }

    .card-content {
        font-size: 0.9rem;
    }
}

/* Small screens (e.g., smartphones, below 768px) */
@media (max-width: 768px) {
    .card-container {
        flex-direction: column; /* Stack cards vertically */
        gap: 20px;
        margin-top: 30px;
    }

    .card {
        width: 100%; /* Full width for small screens */
        max-width: 350px; /* Limit the width of the cards */
        height: auto; /* Allow height to adjust based on content */
        padding: 15px;
    }

    .card-title {
        font-size: 1.2rem;
    }

    .card-content {
        font-size: 0.85rem;
    }
}

/* Extra small screens (e.g., very small smartphones, below 576px) */
@media (max-width: 576px) {
    .dashboard-container {
        height: auto; /* Adjust height to be flexible */
        padding: 10px; /* Reduce padding */
    }

    .card-container {
        gap: 15px;
    }

    .card {
        width: 100%; /* Full width for extra small screens */
        max-width: 100%; /* No maximum width */
        padding: 10px;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-content {
        font-size: 0.75rem;
    }
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start; /* Start content from the top left */
    justify-content: flex-start;
}

.statistics-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.stat-card {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
    margin: 0 0 10px 0;
}

.card-container {
    display: flex;
    gap: 20px;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
}
